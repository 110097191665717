<template>
   <div class="tooltipComp position-absolute" :class="tooltipData.style.tooltipPosition == 'top' ? 'topPos':'bottomPos'" :style="getToolTipPosition(tooltipData.style)"  style="z-index:1000000 !important;" >

                        <div class="tootlTipInner"  :style="getToolTipCompStyles(tooltipData.style,isDesktopView)" style="z-index:1000000 !important;">
                           <!-- <p class="m-0 " :style="getToolTipTextStyle(tooltipData.style)" v-html="tooltipData.toolTipContent"></p> -->
                           <div class="m-0 " style="z-index:1000000 !important;" :style="getToolTipTextStyle(tooltipData.style)" v-html="tooltipData.toolTipContent"></div>
                           <!-- <p class="m-0 " :style="getToolTipTextStyle(tooltipData.style)" >{{tooltipData.toolTipContent}}</p> -->
                        </div>

                     
  </div>
</template>

<script>

import cssFunctionMixin from "../customize/mixin/cssFunction";
export default {
 mixins: [cssFunctionMixin],
 props:['tooltipData','isDesktopView'],

}
</script>

<style scoped>
.tooltipComp.topPos{
display: block;
margin-bottom: 15px;
opacity: 1;
pointer-events: none;
position: absolute;
-webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
   -o-transform: translateY(10px);
    transform: translateY(10px);
-webkit-transition: all .25s ease-out;
  -moz-transition: all .25s ease-out;
  -ms-transition: all .25s ease-out;
   -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
-webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
   -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

.tooltipComp.topPos:before {
bottom: -20px;
content: " ";
display: block;
height: 20px;
left: 0;
position: absolute;
width: 100%;
}


.tooltipComp.topPos:after {
border-left: solid transparent 10px;
border-right: solid transparent 10px;
border-top: solid var(--tooltipArrowColor) 10px;
bottom: -10px;
content: " ";
height: 0;
/* left: 50%; */
left:var(--pseudoLeftPos);
margin-left: -13px;
position: absolute;
width: 0;
}

.tooltipComp.bottomPos{
display: block;
margin-top: 15px;
opacity: 1;
pointer-events: none;
position: absolute;
-webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
   -o-transform: translateY(10px);
    transform: translateY(10px);
-webkit-transition: all .25s ease-out;
  -moz-transition: all .25s ease-out;
  -ms-transition: all .25s ease-out;
   -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
-webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
   -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

.tooltipComp.bottomPos:before {
top: -20px;
content: " ";
display: block;
height: 20px;
left: 0;
position: absolute;
width: 100%;
}


.tooltipComp.bottomPos:after {
border-left: solid transparent 10px;
border-right: solid transparent 10px;
border-bottom: solid var(--tooltipArrowColor) 10px;
top: -10px;
content: " ";
height: 0;
/* left: 50%; */
left: var(--pseudoLeftPos);
margin-left: -13px;
position: absolute;
width: 0;
}
</style>